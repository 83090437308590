import UserNotification from 'components/UserNotification/UserNotification'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import {
  selectors as userNotificationSelectors,
  actions as userNotificationActions,
} from 'store/modules/UserNotification'
import {
  actions as PageActions,
  selectors as pageSelectors
} from 'store/modules/Pagination'


const NotificationContainer = (props) =>{
  const moduleId = 'userNotification'
  const [fetching, setFetching] = useState(false)
  const {getUserNotifcationList, listData, pagination, setCurrentPage, setPerPageLimit, perPage, ...restProps} = props

  useEffect(() =>{
    setCurrentPage(1)
  },[])

  const handlePagination = (perPage) => {
    setCurrentPage(perPage)
    setFetching(true)
    getUserNotifcationList()
  }

  const handlePerpageLimit = (perPage) => {
    setPerPageLimit(perPage)
    setFetching(true)
    getUserNotifcationList()
  }

  return(
    <UserNotification
      perPage= {perPage}
      handlePagination={handlePagination}
      currentPage={pagination?.[moduleId]?.currentPage}
      handlePerpageLimit={handlePerpageLimit}
      getUserNotifcationList={getUserNotifcationList}
      listData={listData}
      fetching={fetching}
      setFetching={setFetching}
      {...restProps}
     />

    )

}

const mapStateToProps = (state, ownProps) => {
  return {
    listData:userNotificationSelectors.listData(state),
    pagination: pageSelectors.pagination(state)?.toJS(),
    perPage: pageSelectors.perPage(state),
    totalCount: userNotificationSelectors.totalCount(state),

  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(
    {
      getUserNotifcationList:() => userNotificationActions.getUserNotificationList('getUserNotifcationList'),
      setCurrentPage: (page) => PageActions.setCurrentPage(page, 'userNotification'),
      setPerPageLimit: (perPage) => PageActions.setPerPageLimit(perPage, 'userNotification'),
    },
    dispatch
  )
}
NotificationContainer.propTypes = {
  getUserNotifcationList: PropTypes.func,
  listData: PropTypes.array,
  pagination: PropTypes.object,
  setCurrentPage: PropTypes.func,
  setPerPageLimit: PropTypes.func,
  perPage: PropTypes.number,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationContainer)
