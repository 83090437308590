import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import './UserNotification.scss'
import TablePagination from 'components/EMDPagination/TablePagination'
import Loader from 'components/Loader'


const UserNotification = ({perPage, totalCount, currentPage, handlePerpageLimit, handlePagination, getUserNotifcationList, listData, fetching, setFetching}) =>{
  const [notfcnData, setNotfcnData] = useState([])

  useEffect(() =>{
    setFetching(true)
    getUserNotifcationList()
   },[])

   useEffect(() =>{
    if(listData){
      const listing = listData?.toJS()
      setFetching(false)
      setNotfcnData(listing)
    }
   },[listData])

  const notfcnList = notfcnData?.map((item) => (
    <li className='notfication-lst' key={item.id}>
      <span>{item?.notificationId?.notificationSubject}</span>
      <div className='read-div'>
        {(!item?.isRead) && <div className='read-icon'></div>}
      </div>
    </li>
  ))


  return (
    <React.Fragment>
     {fetching && <Loader customClass='device-loader-container' />}
      {notfcnData.length > 0 && <div>
        <div className='list-div'>
          <ul>
            {notfcnList}
          </ul>
        </div>
        <TablePagination
          itemsPerPageOptions={[10, 25, 50]}
          itemsPerPageAmount={perPage || 10}
          itemsCount={totalCount || 0}
          currentPage={currentPage || 1}
          onChange={handlePagination}
          onItemsPerPageAmountChange={handlePerpageLimit}
          className='notfication-pagination'
          isDisplayedBelowTable
          />
        </div>}
    </React.Fragment>


  )
}

UserNotification.propTypes = {
  handlePerpageLimit: PropTypes.func,
  handlePagination: PropTypes.func,
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  totalCount: PropTypes.number,
  getUserNotifcationList: PropTypes.func,
  listData: PropTypes.array,
  fetching: PropTypes.bool,
  setFetching: PropTypes.func
}

export default UserNotification
